<template>
  <div>
    <!--    替换文件-->
    <el-dialog
      :title="$t('label.replaces')"
      :visible.sync="replaceDialog"
      :before-close="replaceDialogClose"
      width="50%"
    >
      <div class="upload-box">
        <el-upload
          class="upload-file"
          ref="upload"
          action="#"
          :http-request="uploadFile"
          :on-remove="handleRemove"
          :on-change="handleChange"
          :before-remove="beforeRemove"
          :limit="1"
          :auto-upload="false"
          :on-exceed="handleExceed"
          :file-list="fileList"
        >
          <!--          选择文件-->
          <el-button slot="trigger" size="small" type="primary">{{
            $t("label.selectfile")
          }}</el-button>
        </el-upload>
      </div>
      <div class="dialog-file-edit-item">
        <!--        替换原因（选填）-->
        <div style="color: #333333; margin-bottom: 13px">
          {{ $t("vue_label_file_replace_reason_check") }}
        </div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 8 }"
          v-model="replaceDesc"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="replaceDialogClose" :loading="replaceLoading">{{
          $t("label.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="confirmReplace"
          :loading="replaceLoading"
          >{{ $t("label.replaces") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as fileApi from "../api.js";

export default {
  data() {
    return {
      sizeLimit: 0,
      fileList: [],
      replaceDialog: false,
      replaceLoading: false,
      replaceDesc: "",
      fileId: "",
      fileItem: "",
    };
  },
  mounted() {},
  created() {},
  methods: {
    openPage(obj, id) {
      this.fileItem = obj;
      this.fileId = id;
      fileApi.queryLimit({ type: "file" }).then((res) => {
        this.sizeLimit = Number(res.data.slice(0, res.data.length - 2)) * 1024;
      });
      this.replaceDialog = true;
    },
    handleChange(file, fileList) {
      if (file.size > this.sizeLimit) {
        this.$refs.upload.clearFiles();
        this.$message.warning(this.$i18n.t("vue_label_file_size_limit_tip"));
        return false;
      } else {
        this.fileList = fileList.slice(-3);
      }
    },
    handleRemove() {},
    handleExceed() {
      this.$message.warning(this.$i18n.t("vue_label_file_singel_upload"));
    },
    beforeRemove(file) {
      return this.$confirm(this.$i18n.t("label.Remove") + ` ${file.name}？`);
    },
    replaceDialogClose() {
      this.replaceDialog = false;
      this.replaceDesc = "";
      this.$refs.upload.clearFiles();
    },
    confirmReplace() {
      if (this.$refs.upload.fileList.length > 0) {
        this.replaceLoading = true;
        this.$refs.upload.submit();
      } else {
        this.$message.warning(this.$i18n.t("label.selectuploadfile"));
      }
    },
    uploadFile(file) {
      let formData = new FormData();
      formData.append("file", file.file);
      formData.append("fileName", file.file.name);
      formData.append("id", this.fileId);
      formData.append("message", this.replaceDesc);
      fileApi
        .upNewVer(formData)
        .then(() => {
          this.replaceLoading = false;
          this.replaceDialogClose();
          this.$emit("freshPage", this.fileId);
        })
        .catch(() => {
          this.$message.error(this.$i18n.t("label.uploadfail"));
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-upload {
  width: auto !important;
}
</style>
