<template>
  <div style="width: 100%; height: 100%; padding: 10px 10px 0 10px">
    <div class="home">
      <section v-loading="loading">
        <!--      头部-->
        <div class="contentBox">
          <div class="box_top">
            <!-- 基本信息 -->
            <div class="header_left">
              <i class="el-icon-arrow-left left-back" @click="backPre"></i>
              <svg class="icon box_header" aria-hidden="true">
                <use :href="allIcon[fileItem.type] || defaultIcon"></use>
              </svg>
              <div class="title">
                <!--            文件 FL-->
                <span class="title_top">{{ $t("FL") }}</span>
                <br />
                <span class="title_main">{{
                  fileItem.name + "." + fileItem.type
                }}</span>
              </div>
            </div>
            <div class="header_right">
              <el-button-group style="margin-top: 0">
                <el-button
                  style="color: #006dcc; margin-right: 10px"
                  size="mini"
                  @click="handleFollow(0)"
                  v-loading="btnLoading"
                  v-show="isFollow == 0"
                  >{{ $t("label.chatter.follow") }}</el-button
                >
                <!--                  关注 label.chatter.follow-->
                <el-button
                  style="color: #006dcc; margin-right: 10px"
                  size="mini"
                  @click="handleFollow(1)"
                  v-loading="btnLoading"
                  v-show="isFollow == 1"
                  >{{ $t("label.chatter.following") }}</el-button
                >
                <!--                  正在关注 label.chatter.following-->
                <el-button
                  class="button"
                  style="color: #006dcc"
                  size="mini"
                  v-for="(item, index) in showBtn"
                  :key="index"
                  @click="fileDeal(item)"
                >
                  {{ btnConfig[item] }}
                </el-button>
                <el-popover
                  placement="bottom-end"
                  popper-class="pop_box"
                  trigger="hover"
                  v-if="hideBtn.length > 0"
                >
                  <svg
                    class="icon"
                    aria-hidden="true"
                    style="width: 28px; height: 28px"
                    slot="reference"
                  >
                    <use href="#icon-xiala"></use>
                  </svg>
                  <ul class="moreButton">
                    <li
                      v-for="(item, index) in hideBtn"
                      :key="index"
                      @click="fileDeal(item)"
                      style="cursor: pointer"
                    >
                      {{ btnConfig[item] }}
                    </li>
                  </ul>
                </el-popover>
              </el-button-group>
            </div>
          </div>
        </div>

        <!--      简介-->
        <el-card class="box-card">
          <div class="introduce">
            <div class="introduce_content">
              <!--              大小 label_file_size-->
              <span class="introduce_title">{{ $t("label_file_size") }}</span>
              <br />
              <span class="introduce_cet">{{ fileSize }}</span>
            </div>
            <div class="introduce_content">
              <!--              文件格式-->
              <span class="introduce_title">{{ $t("label.format") }}</span>
              <br />
              <span class="introduce_cet">{{ fileItem.type }}</span>
            </div>
            <div class="introduce_content">
              <!--              所有人 label.owner-->
              <span class="introduce_title">{{ $t("label.owner") }}</span>
              <br />
              <span
                class="introduce_cet item-share-box-name"
                @click="gotoUesrPage(fileItem.ownerid)"
                >{{ fileItem.ownername }}</span
              >
            </div>
          </div>
        </el-card>
        <!--      预览及详情-->
        <div class="file-preview-box">
          <div class="file-preview-left">
            <span class="open-icon" v-show="false" @click="openOrClose">
              <i :class="icons"></i>
            </span>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <!--              预览 label.file.preview-->
              <el-tab-pane :label="$t('label.file.preview')" name="first">
                <div v-show="notHide" @click="openPreview">
                  <div v-if="!canView" class="not-preview">
                    <div class="not-preview-pic">
                      <svg
                        class="icon"
                        aria-hidden="true"
                        style="width: 295px; height: 162px"
                      >
                        <use :href="allIcon['file404']"></use>
                      </svg>
                      <!--                      该文件不支持预览，请下载后查看-->
                      <p style="text-align: center; margin-top: 20px">
                        {{ $t("vue_label_file_not_support_preview") }}
                      </p>
                    </div>
                  </div>
                  <div v-if="canView" class="show-preview">
                    <iframe
                      :src="viewUrl"
                      frameborder="0"
                      height="650px;"
                      width="600px"
                    ></iframe>
                  </div>
                </div>
              </el-tab-pane>
              <!--              详细信息 label.file.details-->
              <el-tab-pane :label="$t('label.file.details')" name="second">
                <div v-show="notHide">
                  <div class="file-info">
                    <div
                      class="file-item"
                      v-for="(item, index) in fileInfo"
                      :key="index"
                    >
                      <div class="file-label">{{ item.label }}</div>
                      <div class="file-value">{{ item.value }}</div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="file-preview-right">
            <div class="file-right-item">
              <div class="item-header">
                <svg class="icon item-header-icon" aria-hidden="true">
                  <use :href="allIcon['shareList']"></use>
                </svg>
                <!--                共享 label.file.share-->
                <span>{{ $t("label.file.share") }}（{{ shareRolesNum }}）</span>
              </div>
              <div class="item-content">
                <div
                  class="item-share"
                  v-for="(item, index) in shareRoles"
                  :key="index"
                >
                  <svg class="icon item-share-icon" aria-hidden="true">
                    <use
                      :href="
                        item.share_group === 'group'
                          ? allIcon['group']
                          : allIcon['followList']
                      "
                    ></use>
                  </svg>

                  <div class="item-share-box">
                    <!--                    人员名称-->
                    <div class="item-share-title">
                      {{ $t("chatter.feed.menutype.user") }}
                    </div>
                    <div class="item-share-content">
                      <span
                        class="item-share-box-name"
                        @click="gotoUesrPage(item.shareTo || fileItem.ownerid)"
                        >{{ item.name }}</span
                      >
                      <span style="margin: 0 5px">|</span>
                      <span
                        >{{ roleConfig[item.share_group] }}（{{
                          roleConfig[item.share_role]
                        }}）</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-footer">
                <!--                查看全部 label.viewall-->
                <span @click="toViewAll(1)">{{ $t("label.viewall") }}</span>
              </div>
            </div>
            <div class="file-right-item" style="height: 350px">
              <div class="item-header">
                <svg class="icon item-header-icon" aria-hidden="true">
                  <use :href="allIcon['historyList']"></use>
                </svg>
                <!--                历史版本-->
                <span
                  >{{ $t("vue_label_file_history_version") }}（{{
                    versionHistoryNum
                  }}）</span
                >
              </div>
              <div class="item-content">
                <div
                  class="item-history"
                  v-for="(item, index) in versionHistoryList"
                  :key="index"
                >
                  <div class="item-history-box">
                    <!--                    版本 label.file.version-->
                    <div
                      class="item-history-title"
                      @click="downloadHistoryVer(item)"
                    >
                      {{ $t("label.file.version") + item.version }}
                    </div>
                    <div class="item-history-content">
                      <span
                        class="item-share-box-name"
                        @click="gotoUesrPage(item.upload_user_id)"
                        >{{ item.name }}</span
                      >
                      <span style="margin: 0 5px">|</span>
                      <span>{{
                        item.createdate | datetimeFormat(countryCode)
                      }}</span>
                    </div>
                    <el-popover
                      placement="bottom-start"
                      width="400"
                      trigger="hover"
                      v-show="item.message"
                      :content="item.message"
                    >
                      <!--                      替换原因-->
                      <div slot="reference" class="item-history-reason">
                        {{ $t("vue_label_file_replace_reason") }}：{{
                          item.message
                        }}
                      </div>
                    </el-popover>
                  </div>
                </div>
              </div>
              <div class="item-footer">
                <!--                查看全部-->
                <span @click="toViewAll(2)">{{ $t("label.viewall") }}</span>
              </div>
            </div>
            <div class="file-right-item">
              <div class="item-header">
                <svg class="icon item-header-icon" aria-hidden="true">
                  <use :href="allIcon['followList']"></use>
                </svg>
                <!--                关注者-->
                <span
                  >{{ $t("vue_label_CCChat_followers") }}（{{
                    followListNum
                  }}）</span
                >
              </div>
              <div class="item-content">
                <div
                  class="item-share"
                  v-for="(item, index) in followList"
                  :key="index"
                >
                  <svg class="icon item-share-icon" aria-hidden="true">
                    <use :href="allIcon['followList']"></use>
                  </svg>
                  <div
                    class="item-share-box"
                    @click="gotoUesrPage(item.user_id)"
                  >
                    <span class="item-share-box-name">{{ item.name }}</span>
                  </div>
                </div>
              </div>
              <div class="item-footer">
                <!--                查看全部-->
                <span @click="toViewAll(3)">{{ $t("label.viewall") }}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!--    删除文件  label.file.delete.info-->
    <el-dialog
      :title="$t('label.file.delete.info')"
      :visible.sync="dialogDelete"
      width="30%"
    >
      <!--      确认删除 label.sure.delete-->
      <span
        >{{ $t("label.sure.delete") }}
        <span style="color: dodgerblue"
          >{{ fileItem.name + "." + fileItem.type }}？</span
        ></span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDelete = false" :loading="deleteLoading">{{
          $t("label.cancel")
        }}</el-button>
        <!--    确定    label.confirm-->
        <el-button
          type="primary"
          @click="confirmDelete"
          :loading="deleteLoading"
          >{{ $t("label.confirm") }}</el-button
        >
      </span>
    </el-dialog>
    <!--    编辑文件-->
    <el-dialog title="" :visible.sync="dialogFileEdit" width="30%">
      <div slot="title" class="header-title">
        <span>{{ $t("label.modify") + fileItem.name }}</span>
      </div>
      <div class="dialog-file-edit-item">
        <!--        文件名 label.addattachment.name-->
        <div>
          <span style="color: red">*</span>{{ $t("label.addattachment.name") }}
        </div>
        <el-input v-model="editFileDetail.name"></el-input>
      </div>
      <div class="dialog-file-edit-item">
        <!--        描述 label.file.des-->
        <div>{{ $t("label.file.des") }}</div>
        <el-input v-model="editFileDetail.description"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--        取消 label.cancel-->
        <el-button @click="dialogFileEdit = false" :loading="editLoading">{{
          $t("label.cancel")
        }}</el-button>
        <!--        保存 label.save-->
        <el-button
          type="primary"
          @click="confirmFileEdit"
          :loading="editLoading"
          >{{ $t("label.save") }}</el-button
        >
      </span>
    </el-dialog>
    <!--    公用链接-->
    <file-preview
      :show="showPreview"
      :showUrl="previewUrl"
      :showData="showPreviewData"
      @closePreview="closePreview"
      @handleFile="handleFileClick"
      @replaceFile="replaceFileClick"
      ref="previewFile"
    >
    </file-preview>
    <file-share-role
      ref="isShare"
      :isFromDetail="true"
      @freshDetail="getShareRoles"
    ></file-share-role>
    <replace-file ref="replaceFile" @freshPage="initPage"></replace-file>
    <public-link ref="publicLinkFile"></public-link>
  </div>
</template>

<script>
import filePreview from "@/components/FileView/filePreview";
import ReplaceFile from "./components/ReplaceFile";
import PublicLink from "./components/PublicLink";
import FileShareRole from "./components/FileShareRole";
import FILEICON from "@/views/ccFileCenter/config/fileIconLibrary";
import * as fileApi from "./api.js";
import * as api from "./api.js";
import * as Time from "@/utils/time";

export default {
  name: "fileDetail",
  components: {
    FileShareRole,
    ReplaceFile,
    PublicLink,
    filePreview,
  },
  data() {
    return {
      btnLoading: false,
      showRepalce: false,
      isFollow: -1,
      roleConfig: {
        person: this.$i18n.t("label.chat.likeperson"),
        group: this.$i18n.t("label.ccchat.group.section"),
        check: this.$i18n.t("label.readonly"),
        cooperation: this.$i18n.t("label.readwrite"),
        owner: this.$i18n.t("label.file.owner"),
      },
      shareRoles: [], // 分享者
      shareRolesNum: 0, // 分享者数量
      versionHistoryList: [], // 历史版本
      versionHistoryNum: 0, // 历史版本
      followList: [], // 关注者
      followListNum: 0, // 关注者数量
      showPreview: false, // 预览
      showPreviewData: {}, // 预览数据
      previewUrl: "",
      previewImgUrl: "",
      loading: false,
      editLoading: false, // 编辑
      dialogFileEdit: false, // 编辑文件
      editFileDetail: {}, // 编辑信息回显
      deleteLoading: false, // 删除
      dialogDelete: false, // 删除
      defaultIcon: FILEICON.default, // 文件缺省图标
      allIcon: FILEICON, // 文件图标
      fileId: this.$route.query.id,
      fileItem: {},
      fileSize: "",
      notHide: true,
      fileInfo: [
        // 文件名 label.addattachment.name
        {
          label: this.$i18n.t("label.addattachment.name"),
          value: "",
          prop: "name",
        },
        // 描述 label.file.des
        {
          label: this.$i18n.t("label.file.des"),
          value: "",
          prop: "description",
        },
      ],
      icons: "el-icon-arrow-up",
      activeName: "first",
      showBtn: [],
      hideBtn: [],
      // 按钮相关
      btnConfig: {
        download: this.$i18n.t("chatter.down"), // 下载
        publicLink: this.$i18n.t("label.file.publiclink"), // 公共链接
        share: this.$i18n.t("label.file.share"), // 共享
        delete: this.$i18n.t("label.delete"), // 删除
        editinfo: this.$i18n.t("label.modify"), // 编辑
        replace: this.$i18n.t("label.replaces"), // 替换
      },
      // 新版预览
      viewUrl: "",
      typeArr: [
        "pdf",
        "doc",
        "docx",
        "xlsx",
        "xls",
        "pptx",
        "ppt",
        "zip",
        "jpg",
        "jpeg",
        "png",
        "tiff",
        "mp4",
        "txt",
        "ofd",
        "rar",
        "rtf",
        "csv",
        "wps",
      ],
      canView: true,
      isImg: false,
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      timeFormat: Time.timeFormat(this.$cookies.get("countryCode")),
      countryCode: this.$cookies.get("countryCode"),
    };
  },
  mounted() {
    document.title = this.$setTitle(this.$i18n.t("FL"));
    this.initPage(this.$route.query.id);
  },
  methods: {
    // 初始化数据
    initPage(id) {
      this.fileDetail(id);
      this.previewCheck(id);
      this.getShareRoles(id);
      this.getVersionHis(id);
      this.getfollowList(id);
    },
    gotoUesrPage(id) {
      this.$router.push({
        path: `/commonObjects/detail/${id}/DETAIL`,
      });
    },
    downloadHistoryVer(option) {
      const baseURL = this.$baseConfig.baseURL;
      const token = this.$CCDK.CCToken.getToken();
      const link = document.createElement("a");
      link.setAttribute("download", "");
      link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${this.fileId}&versonId=${option.id}`;
      link.click();
    },
    // 点击关注
    handleFollow(flag) {
      this.btnLoading = true;
      if (flag < 1) {
        fileApi.addFollow({ id: this.fileId }).then(() => {
          this.isFollow = 1;
          this.getfollowList(this.fileId);
          // 关注成功
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("vue_label_notice_follow_success"),
          });
          this.btnLoading = false;
        });
      } else {
        fileApi.cancelFollow({ id: this.fileId }).then(() => {
          this.isFollow = 0;
          this.getfollowList(this.fileId);
          // 取消关注成功
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("vue_label_notice_unfollow_success"),
          });
          this.btnLoading = false;
        });
      }
    },
    // 获取共享列表
    getShareRoles(id) {
      fileApi.getShareList({ id: id }).then((res) => {
        this.shareRolesNum = res.data.shareList.length;
        this.shareRoles = res.data.shareList;
        if (this.shareRoles.length > 3) {
          this.shareRoles.length = 3;
          this.shareRolesNum = "3+";
        }
      });
    },
    // 获取历史版本
    getVersionHis(id) {
      fileApi.versionHistory({ id: id, type: "all" }).then((res) => {
        this.versionHistoryNum = res.data.length;
        this.versionHistoryList = res.data;
        if (this.versionHistoryList.length > 3) {
          this.versionHistoryList.length = 3;
          this.versionHistoryNum = "3+";
        }
      });
    },
    // 获取关注列表
    getfollowList(id) {
      fileApi.getFollow({ id: id, type: "all" }).then((res) => {
        this.followListNum = res.data.length;
        this.followList = res.data;
        if (this.followList.length > 3) {
          this.followList.length = 3;
          this.followListNum = "3+";
        }
      });
    },
    // 查看全部
    toViewAll(flag) {
      this.$router.push({
        path: "/ccFileCenter/detailList",
        query: {
          id: this.fileId,
          type: flag,
          owner: this.fileItem.ownerid,
        },
      });
    },
    // 编辑信息回显
    editInfo(item) {
      fileApi
        .editFile({
          id: item.id,
        })
        .then((res) => {
          this.editFileDetail = res.data;
          this.dialogFileEdit = true;
        });
    },
    // 保存编辑信息
    confirmFileEdit() {
      if (this.editFileDetail.name.replace(/\s+/g, "") !== "") {
        // if (this.editFileDetail.name !== undefined&&this.editFileDetail.name !== null&&this.editFileDetail.name !== ""){
        this.editLoading = true;
        fileApi
          .saveEditFile({
            id: this.fileItem.id,
            name: this.editFileDetail.name,
            description: this.editFileDetail.description || "",
          })
          .then(() => {
            this.dialogFileEdit = false;
            this.fileDetail(this.$route.query.id);
            // 保存成功 savesuccess
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("savesuccess"),
            });
            this.editLoading = false;
          });
      } else {
        // 请填写文件名 label.weixin.input.filename
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("label.weixin.input.filename"),
        });
      }
    },
    // 获取详情
    async fileDetail(id) {
      this.loading = true;
      let { data } = await fileApi.getFileDetail({
        id: id,
      });

      document.title = this.$setTitle(data ? data[0].name : "");
      this.closePreview();
      this.fileItem = data ? data[0] : {};
      this.fileSize = this.switchSize(this.fileItem.fileSize);
      var btnArr = [];
      const baseArr = this.fileItem.button;
      baseArr.map((item) => {
        if (item === "unfollow") {
          this.isFollow = 0;
        } else if (item === "following") {
          this.isFollow = 1;
        } else if (item === "editinfo") {
          this.showRepalce = true;
        }
        if (
          item !== "viewinfo" &&
          item !== "openin" &&
          item !== "unfollow" &&
          item !== "following" &&
          item !== "publicLink"
        ) {
          btnArr.push(item);
        }
      });
      if (this.showRepalce) {
        btnArr.push("replace");
      }
      if (btnArr.length <= 3) {
        this.showBtn = btnArr;
      } else {
        this.showBtn = btnArr.slice(0, 3);
        this.hideBtn = btnArr.slice(3);
      }
      this.fileInfo.forEach((item) => {
        if (item.prop !== "fileSize") {
          item.value = this.fileItem[item.prop];
        } else {
          item.value = this.fileSize;
        }
      });

      // 判断预览格式 是否支持预览
      if (this.typeArr.indexOf(this.fileItem.type.toLowerCase()) === -1) {
        // 不支持预览
        this.canView = false;
      }

      /**
       * 1、先在前端筛选一遍文件支持列表
       * 2、最后才要调用接口去查看是否支持
       */

      if (id) {
        await this.previewCheck(id);
      }

      this.loading = false;
    },
    /**
     *  third preview if support
     *plugIns和flage 同时为true 可以预览，其他情况都提示不支持
     */
    async previewCheck(id) {
      let {
        data: { flage, plugIns, previewurl },
      } = await api.preview({
        id,
      });
      if (flage !== "true" || plugIns !== "true") {
        this.canView = false;
        return Promise.resolve(false);
      }

      this.viewUrl = previewurl;
      this.canView = true;
      return Promise.resolve(true);
    },
    // 打开预览
    openPreview() {
      // 列表打开预览
      this.showPreview = true;
      this.showPreviewData = this.fileItem;
      let arr = [];
      arr = this.fileItem.button.filter((item) => item !== "viewinfo");
      this.$refs.previewFile.handleBtn(arr);
      fileApi
        .preview({
          id: this.fileItem.id,
        })
        .then((res) => {
          if (res.data.file_content_id) {
            this.previewUrl = res.data.previewurl;
          } else {
            this.previewUrl = "";
          }
        });
    },
    // 文件大小转换
    switchSize(filesize) {
      if (filesize < 0.1 * 1024) {
        //如果小于0.1KB转化成B
        return filesize.toFixed(2) + "B";
      } else if (filesize < 0.1 * 1024 * 1024) {
        //如果小于0.1MB转化成KB
        return (filesize / 1024).toFixed(2) + "KB";
      } else if (filesize < 0.1 * 1024 * 1024 * 1024) {
        //如果小于0.1GB转化成MB
        return (filesize / (1024 * 1024)).toFixed(2) + "MB";
      } else {
        //大于100M转化为GB
        return (filesize / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      }
    },
    // 收起关闭
    handleClick() {
      this.icons = "el-icon-arrow-up";
      this.notHide = true;
    },
    // 收起关闭
    openOrClose() {
      this.icons =
        this.icons === "el-icon-arrow-down"
          ? "el-icon-arrow-up"
          : "el-icon-arrow-down";
      this.notHide = this.icons === "el-icon-arrow-up" ? true : false;
    },
    // 回退页面
    backPre() {
      this.$router.go(-1);
    },
    // 删除文件
    deleteItem() {
      this.dialogDelete = true;
    },
    // 确认删除文件
    confirmDelete() {
      this.deleteLoading = true;
      fileApi
        .deleteFile({
          id: this.fileItem.id,
        })
        .then(() => {
          this.dialogDelete = false;
          this.deleteLoading = false;
          // 文件删除成功 label.file.delete.success
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("label.file.delete.success"),
          });
          this.backPre();
        });
    },
    // 下载
    downloadFile(item) {
      const baseURL = this.$baseConfig.baseURL;
      const token = this.$CCDK.CCToken.getToken();
      const link = document.createElement("a");
      link.setAttribute("download", "");
      link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${item.id}`;
      link.click();
    },
    // 按钮处理
    fileDeal(item) {
      switch (item) {
        // 公共链接
        case "publicLink":
          this.$refs.publicLinkFile.openPage(this.fileItem, this.fileId);
          break;
        // 下载
        case "download":
          this.downloadFile(this.fileItem);
          break;
        // 编辑信息
        case "editinfo":
          this.editInfo(this.fileItem);
          break;
        // 分享
        case "share":
          this.handleShare(this.fileItem);
          break;
        // 删除
        case "delete":
          this.deleteItem(this.fileItem);
          break;
        // 打开
        case "openin":
          this.openPreview(this.fileItem);
          break;
        // 替换
        case "replace":
          this.$refs.replaceFile.openPage(this.fileItem, this.fileId);
          break;
      }
    },
    handleShare(obj) {
      this.$refs.isShare.open(this.fileId, obj.name);
    },
    // 关闭预览
    closePreview() {
      this.showPreview = false;
      this.previewUrl = "";
    },
    // 子组件回传按钮点击操作
    handleFileClick(item) {
      this.fileDeal(item);
    },
    replaceFileClick() {
      this.replaceFile();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  overflow: auto;
}
.box_top {
  background: #f3f2f2;
  border: 1px solid #dddbda;
  border-radius: 3px;
  //border-bottom: 1px solid #dddbda;
  min-height: 69px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header_left {
    display: flex;
    align-items: center;
    .left-back {
      width: 7px;
      height: 12px;
      margin-right: 18px;
      float: left;
      cursor: pointer;
    }
    .box_header {
      width: 36px;
      height: 36px;
    }
    .title {
      height: 36px;
      margin-left: 8px;
      .title_top {
        height: 12px;
        font-size: 12px;
        font-weight: 400;
        color: rgba(62, 62, 60, 1);
        line-height: 18px;
      }
      .title_main {
        height: 15px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(34, 34, 34, 1);
        line-height: 15px;
      }
    }
  }
}
.moreButton {
  margin-bottom: 0;
  text-align: center;
  li {
    height: 30px;
    line-height: 30px;

    &:hover {
      color: #006dcc;
    }
  }
}
.box-card {
  width: 100%;

  border-radius: 3px;
  border: 1px solid rgba(221, 219, 218, 1);

  ::v-deep .el-card__body {
    padding: 0;
  }
  .introduce {
    width: 100%;
    height: auto;
    display: flex;

    .introduce_title {
      width: 24px;
      height: 15px;
      font-size: 12px;

      font-weight: 400;
      color: rgba(62, 62, 60, 1);
      line-height: 22px;
    }

    .introduce_cet {
      width: 48px;
      height: 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }

    .introduce_content {
      float: left;
      margin: 10px 0 10px 35px;
      min-width: 20px;
    }
  }
}
.file-preview-box {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  .file-preview-left {
    background-color: #fff;
    width: 70%;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: 1px solid #dddbda;
    margin-top: 10px;
    padding: 10px;
    margin-right: 10px;
    box-sizing: border-box;
    .open-icon {
      font-size: 16px;
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      z-index: 1;
    }
  }
  .file-preview-right {
    width: 30%;
    .file-right-item {
      min-height: 311px;
      margin-top: 10px;
      background-color: #fff;
      position: relative;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      border: 1px solid #dddbda;
      box-sizing: border-box;
      .item-header {
        height: 45px;
        font-size: 16px;
        box-sizing: border-box;
        padding: 20px;
        border-bottom: 1px solid #dddbda;
        color: #080707;
        display: flex;
        align-items: center;
        .item-header-icon {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
      .item-content {
        .item-share {
          height: 60px;
          background: #fafaf9;
          margin-bottom: 4px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          box-sizing: border-box;
          .item-share-icon {
            height: 34px;
            width: 34px;
            margin-right: 5px;
          }
          .item-share-box {
            width: 100%;
            .item-share-title {
              font-size: 12px;
              color: #666666;
            }
            .item-share-content {
              font-size: 14px;
              color: #333333;
            }
          }
        }
        .item-history {
          height: 80px;
          background: #fafaf9;
          margin-bottom: 4px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          box-sizing: border-box;
          .item-history-box {
            width: 100%;
            .item-history-title {
              font-size: 14px;
              color: #080707;
              cursor: pointer;
            }
            .item-history-title:hover {
              color: rgb(0, 109, 204);
            }
            .item-history-content {
              font-size: 14px;
              color: #333333;
            }
            .item-history-reason {
              font-size: 14px;
              color: #333333;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              cursor: pointer;
            }
          }
        }
      }
      .item-footer {
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 47px;
        border-top: 1px solid #dddbda;
        width: 100%;
        > span {
          font-size: 14px;
          color: #006dcc;
          cursor: pointer;
        }
      }
    }
  }
}
.not-preview {
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  .not-preview-pic {
    //display: flex;
    //justify-content: center;
    //flex-wrap: wrap;
    text-align: center;
  }
}
.show-preview {
  width: 100%;
  max-height: 800px;
  //margin-top: -90px;
  margin-left: -2px;
  display: flex;
  justify-content: center;
}
.can-preview {
  min-height: 450px;
}
.file-info {
  min-height: 450px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: flex-start;
  .file-item {
    width: 52%;
    border-bottom: 0.5px solid #dedcda;
    margin-bottom: 10px;
    .file-label {
      height: 28px;
      padding: 0;
      font-size: 12px;
      color: #666;
    }
    .file-value {
      height: 24px;
      padding: 0;
      font-size: 12px;
      color: #333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  ::v-deep .cui-ribbon *,
  .cui-toolbar-toolbar *,
  .cui-viewerchrome * {
    display: none;
  }
}
.upload-box {
  height: 70px;
}
.header-title {
  display: flex;
  align-items: center;
}
.item-share-box-name {
  cursor: pointer;
}
.item-share-box-name:hover {
  color: rgb(0, 109, 204);
}
</style>
